//1-base
//@import "1-base/normalize";
//2-layout
//@import "2-layout/layout";
//3-components
//@import "3-components/nav";
// @import "3-components/paralax";
//@import "3-components/ionicons";

//vendors
// @import "../vendors/photoswipe/css/_main-settings";
// @import "../vendors/photoswipe/css/main";
// @import "../vendors/photoswipe/css/default-skin/default-skin";
// @import "../vendors/photoswipe/css/photoswipe_video";

//4-theme
//@import "4-theme/colors";
//@import "4-theme/cursors";
//@import "4-theme/typographie";
//@import "4-theme/style";
//@import "4-theme/shared";
//@import "4-theme/media_queries";
//@import "4-theme/animation_marquee";
//@import "4-theme/animation_scale";

//@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css";

// @import "3-components/paralax";


// for scroll animations
// @import "sal.js/dist/sal";
// @import "3-components/sal-custom";

//enable smoth scroll
html {
    scroll-behavior: smooth;
}

/*lazyload*/
/* fade image in after load */
.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}


